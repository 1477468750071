/** Nextjs */
import { useSession } from "next-auth/react";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";

/** UI */
const IndexLayout = dynamic(() => import("@/src/layouts/IndexLayout"));
const IndexPage = dynamic(() => import("@/src/pages/Index"));
const FullpagePreloader = dynamic(() => import("@/src/shared/FullpagePreloader"));

const Home = (props) => {
    const { data: session, status } = useSession();
    const { push } = useRouter();

    if (status === "loading") {
        return (
            <IndexLayout>
                <FullpagePreloader />
            </IndexLayout>
        );
    }

    if (status === "authenticated") {
        push("/projects");
    }

    if (status === "unauthenticated") {
        push("/login");
    }

    return (
        <IndexLayout>
            <FullpagePreloader />
            <IndexPage />
        </IndexLayout>
    );
};

export default Home;
